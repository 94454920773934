import { apiFetch } from '../../../util/apiFetch';
import { SynchronizationScope } from '../model/SynchronizationScope';

const getScopeUrl = (
    scope: SynchronizationScope,
    updated: string | null,
    minCompleteness: number | null,
    filterAttribute: string | null,
    priceImport: boolean,
    withCustomFields: boolean,
    withImages: boolean,
    withImportPreExistingProductsOnly: boolean,
    withCategories: boolean,
    storeId: string
) => {
    if (scope === SynchronizationScope.Category) {
        if (updated) {
            return `${storeId}/synchronize/categories/differentially?updated_date=${updated}`;
        }

        return `${storeId}/synchronize/categories/fully`;
    }

    const priceImportFormatted = priceImport ? 1 : 0;
    const withCustomFieldsFormatted = withCustomFields ? 1 : 0;
    const withImagesFormatted = withImages ? 1 : 0;
    const withImportPreExistingProductsOnlyFormatted =
        withImportPreExistingProductsOnly ? 1 : 0;
    const withCategoriesFormatted = withCategories ? 1 : 0;

    if (scope === SynchronizationScope.Product) {
        const completeness = minCompleteness ? minCompleteness.toString() : '';

        if (updated) {
            return (
                `${storeId}/synchronize/products/differentially?` +
                `updated_date=${updated}` +
                `&min_completeness=${completeness}` +
                `&with_images=${withImagesFormatted}` +
                `&price_import=${priceImportFormatted}` +
                `&with_custom_fields=${withCustomFieldsFormatted}` +
                `&with_import_pre_existing_products_only=${withImportPreExistingProductsOnlyFormatted}` +
                `&with_categories=${withCategoriesFormatted}` +
                `&filterAttribute=${filterAttribute}`
            );
        } else {
            return (
                `${storeId}/synchronize/products/fully?` +
                `min_completeness=${completeness}` +
                `&with_images=${withImagesFormatted}` +
                `&price_import=${priceImportFormatted}` +
                `&with_custom_fields=${withCustomFieldsFormatted}` +
                `&with_import_pre_existing_products_only=${withImportPreExistingProductsOnlyFormatted}` +
                `&with_categories=${withCategoriesFormatted}` +
                `&filterAttribute=${filterAttribute}`
            );
        }
    }

    if (scope === SynchronizationScope.ProductVariant) {
        if (updated) {
            return (
                `${storeId}/synchronize/product-variants/differentially?` +
                `updated_date=${updated}` +
                `&price_import=${priceImportFormatted}` +
                `&with_custom_fields=${withCustomFieldsFormatted}` +
                `&with_images=${withImagesFormatted}` +
                `&with_import_pre_existing_products_only=${withImportPreExistingProductsOnlyFormatted}` +
                `&with_categories=${withCategoriesFormatted}` +
                `&filterAttribute=${filterAttribute}`
            );
        }

        return (
            `${storeId}/synchronize/product-variants/fully?` +
            `price_import=${priceImportFormatted}` +
            `&with_custom_fields=${withCustomFieldsFormatted}` +
            `&with_images=${withImagesFormatted}` +
            `&with_import_pre_existing_products_only=${withImportPreExistingProductsOnlyFormatted}` +
            `&with_categories=${withCategoriesFormatted}` +
            `&filterAttribute=${filterAttribute}`
        );
    }

    throw new Error();
};

/**
 * @param scope SynchronizationScope: category, product and product variant
 * @param updated Date and time ISO8601 format set to UTC timezone
 * @param minCompleteness Filter on minimum completeness
 * @param filterAttribute
 * @param priceImport Define if the prices should be imported during update
 * @param withCustomFields Define if the custom fields should be imported during update
 * @param withImages Define if the images should be imported during update
 * @param withImportPreExistingProductsOnly Define if ony pre-existing catalog must be imported or not
 * @param withCategories Define if the categories must be imported during update
 */
export const launchSynchronization = ({
    scope,
    updated,
    minCompleteness,
    filterAttribute,
    priceImport,
    withCustomFields,
    withImages,
    withImportPreExistingProductsOnly,
    withCategories,
    storeId,
}: {
    scope: SynchronizationScope;
    updated: string | null;
    minCompleteness: number | null;
    filterAttribute: string | null;
    priceImport: boolean;
    withCustomFields: boolean;
    withImages: boolean;
    withImportPreExistingProductsOnly: boolean;
    withCategories: boolean;
    storeId: string;
}) => {
    return apiFetch(
        getScopeUrl(
            scope,
            updated,
            minCompleteness,
            filterAttribute,
            priceImport,
            withCustomFields,
            withImages,
            withImportPreExistingProductsOnly,
            withCategories,
            storeId
        ),
        {
            method: 'POST',
        }
    );
};
