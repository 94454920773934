import React from 'react';
import { SectionTitle, Table, getColor } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { TableRow } from 'akeneo-design-system/lib/components/Table/TableRow/TableRow';
import {
    ConfigurationModel,
    SynchronizationModel,
} from '../model/SynchronizationReportModel';
import { isNumber } from 'lodash';
import { SynchronizationScope } from '../../Synchronization/model/SynchronizationScope';

const ConfigurationReportContainer = styled.div`
    width: 100%;
`;

const Label = styled.div`
    color: ${getColor('brand', 100)};
    width: 30%;
    align-self: center;
`;

const TableCellContent = styled.div`
    display: flex;
    flex-direction: column;
`;

type Props = {
    configuration: ConfigurationModel | null;
    synchronization: SynchronizationModel | null;
};

export const ConfigurationReport = ({
    configuration,
    synchronization,
}: Props) => {
    let filters: boolean = false;
    if (
        configuration!.filters.filter_attribute ||
        configuration!.filters.min_completeness ||
        configuration?.filters.pre_existing_catalog_only
    ) {
        filters = true;
    }

    const content = [
        configuration!.with_images && 'Media',
        configuration!.with_custom_fields && 'Custom fields',
        configuration!.price_import && 'Prices',
        configuration!.with_categories && 'Categories',
    ].filter(Boolean) as string[];

    const createdAt = new Date(synchronization!.created_at.date);

    return (
        <>
            <ConfigurationReportContainer>
                <SectionTitle>
                    <SectionTitle.Title>
                        {!configuration?.is_scheduled_sync ? (
                            <FormattedMessage
                                id='/o6xSU'
                                defaultMessage='Manual synchronization - configuration'
                            />
                        ) : (
                            <FormattedMessage
                                id='+6jJYc'
                                defaultMessage='Scheduled synchronization - configuration'
                            />
                        )}
                    </SectionTitle.Title>
                </SectionTitle>

                <Table>
                    <Table.Body>
                        <TableRow>
                            <Table.Cell>
                                <Label>
                                    <FormattedMessage
                                        id='t4Jiip'
                                        defaultMessage='Import process'
                                    />
                                </Label>
                            </Table.Cell>
                            <Table.Cell>
                                <TableCellContent>
                                    {/* Scope */}
                                    {configuration!.scope ===
                                        SynchronizationScope.Product && (
                                        <>
                                            <div>
                                                <FormattedMessage
                                                    id='05FPn1'
                                                    defaultMessage='Scope: Products'
                                                />
                                            </div>
                                        </>
                                    )}
                                    {configuration!.scope ===
                                        SynchronizationScope.ProductVariant && (
                                        <>
                                            <div>
                                                <FormattedMessage
                                                    id='UQPWUJ'
                                                    defaultMessage='Scope: Products with variants'
                                                />
                                            </div>
                                        </>
                                    )}

                                    {/* Delta import */}
                                    {configuration!.type === 'FULL' ? (
                                        <>
                                            <div>
                                                <FormattedMessage
                                                    id='C8iUSa'
                                                    defaultMessage='Full import: {created_at}'
                                                    values={{
                                                        created_at:
                                                            createdAt.toLocaleDateString() +
                                                            ' - ' +
                                                            createdAt.toLocaleTimeString(),
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        [
                                            <>
                                                <div>
                                                    <FormattedMessage
                                                        id='Uc7E6b'
                                                        defaultMessage='Delta import: {created_at}'
                                                        values={{
                                                            created_at:
                                                                createdAt.toLocaleDateString() +
                                                                ' - ' +
                                                                createdAt.toLocaleTimeString(),
                                                        }}
                                                    />
                                                </div>
                                            </>,
                                        ]
                                    )}

                                    {/* Reference date */}
                                    {configuration!.reference_date && (
                                        <div>
                                            <FormattedMessage
                                                id='PEVUwW'
                                                defaultMessage='Reference date: {reference_date}'
                                                values={{
                                                    reference_date:
                                                        new Date(
                                                            configuration!.reference_date.date
                                                        ).toLocaleDateString() +
                                                        ' - ' +
                                                        new Date(
                                                            configuration!.reference_date.date
                                                        ).toLocaleTimeString(),
                                                }}
                                            />
                                        </div>
                                    )}
                                </TableCellContent>
                            </Table.Cell>
                        </TableRow>
                        {filters && (
                            <TableRow>
                                <Table.Cell>
                                    <Label>
                                        <FormattedMessage
                                            id='zSOvI0'
                                            defaultMessage='Filters'
                                        />
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>
                                    <TableCellContent>
                                        {/* Filter attribute */}
                                        {configuration!.filters
                                            .filter_attribute && (
                                            <>
                                                <div>
                                                    <FormattedMessage
                                                        id='1ftOA4'
                                                        defaultMessage='Filter attribute: {filter_attribute}'
                                                        values={{
                                                            filter_attribute:
                                                                configuration!
                                                                    .filters
                                                                    .filter_attribute,
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {/* Completeness */}

                                        {isNumber(
                                            configuration!.filters
                                                .min_completeness
                                        ) &&
                                            configuration!.filters
                                                .min_completeness > 0 && (
                                                <>
                                                    <div>
                                                        <FormattedMessage
                                                            id='jQ/0mu'
                                                            defaultMessage='Completeness: {min_completeness}%'
                                                            values={{
                                                                min_completeness:
                                                                    configuration!
                                                                        .filters
                                                                        .min_completeness,
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                        {/* Pre-existing products only */}
                                        {configuration?.filters
                                            .pre_existing_catalog_only && (
                                            <>
                                                <div>
                                                    <FormattedMessage
                                                        id='KQTGI1'
                                                        defaultMessage='Pre-existing products only'
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </TableCellContent>
                                </Table.Cell>
                            </TableRow>
                        )}

                        {(configuration!.with_images ||
                            configuration!.with_custom_fields ||
                            configuration!.price_import ||
                            configuration!.locales ||
                            configuration!.with_categories) && (
                            <TableRow>
                                <Table.Cell>
                                    <Label>
                                        <FormattedMessage
                                            id='Jq3FDz'
                                            defaultMessage='Content'
                                        />
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>
                                    <TableCellContent>
                                        {/* Prices, Media, Custom fields */}
                                        <div>
                                            <FormattedMessage
                                                id='zb24ln'
                                                defaultMessage='{content}'
                                                values={{
                                                    content: content.join(', '),
                                                }}
                                            />
                                        </div>
                                        {/* Locales:  */}
                                        <div>
                                            <FormattedMessage
                                                id='W/1F/j'
                                                defaultMessage='Locales: {locales}'
                                                values={{
                                                    locales:
                                                        configuration!.locales.join(
                                                            ', '
                                                        ),
                                                }}
                                            />
                                        </div>
                                    </TableCellContent>
                                </Table.Cell>
                            </TableRow>
                        )}
                    </Table.Body>
                </Table>
            </ConfigurationReportContainer>
        </>
    );
};
