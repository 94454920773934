import {
    Button,
    Checkbox,
    Field,
    Helper,
    Modal,
    ProductsIllustration,
    SelectInput,
} from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { SynchronizationScope } from '../../model/SynchronizationScope';
import { SynchronizationScopeLabel } from '../../component/SynchronizationScopeLabel';
import styled from 'styled-components';
import { FilterPimAttribute } from '../../model/FilterPimAttribute';

type Props = {
    closeModal: () => void;
    onSchedule: (
        frequency: string,
        filterAttribute: string | null,
        priceImport: boolean,
        withCustomFields: boolean,
        withImages: boolean,
        withImportPreExistingProductsOnly: boolean,
        withCategories: boolean
    ) => void;
    scope: SynchronizationScope;
    initialFrequency: string;
    initialFilterAttribute: string | null;
    filterPimAttributes: FilterPimAttribute[];
    initialPriceImport: boolean;
    initialWithCustomFields: boolean;
    initialWithImages: boolean;
    initialWithImportPreExistingProductsOnly: boolean;
    initialWithCategories: boolean;
    isPreExistingCatalogMappingConfigured: boolean;
    isProductMediaMappingEmpty: boolean;
    isCustomFieldMappingEmpty: boolean;
};

const ModalContent = styled.div`
    min-height: 100px;
`;

const StyledHelper = styled(Helper)`
    font-size: 11px;
`;

const CheckboxLabelContainer = styled.div`
    font-size: ${({ theme }) => theme.fontSize.default};
`;

const CheckboxHelper = styled(Helper)`
    font-size: 11px;
    margin-top: 5px;
`;

const ScheduledSyncConfigModal = ({
    closeModal,
    onSchedule,
    scope,
    initialFrequency,
    initialFilterAttribute,
    filterPimAttributes,
    initialPriceImport,
    initialWithCustomFields,
    initialWithImages,
    initialWithImportPreExistingProductsOnly,
    initialWithCategories,
    isPreExistingCatalogMappingConfigured,
    isProductMediaMappingEmpty,
    isCustomFieldMappingEmpty,
}: Props) => {
    const [frequency, setFrequency] = useState(initialFrequency);

    const onFrequencyChange = (frequency: string) => {
        setFrequency(frequency);
    };

    const [filterAttribute, setFilterAttribute] = useState<string | null>(
        initialFilterAttribute
    );

    const [priceImport, setPriceImport] = useState<boolean>(initialPriceImport);
    const [withCustomFields, setCustomFieldsFilter] = useState<boolean>(
        initialWithCustomFields
    );

    const [withImages, setImagesFilter] = useState<boolean>(initialWithImages);
    const [
        withImportPreExistingProductsOnly,
        setImportPreExistingProductsOnlyFilter,
    ] = useState<boolean>(initialWithImportPreExistingProductsOnly);

    const [withCategories, setCategoriesFilter] = useState<boolean>(
        initialWithCategories
    );

    const handleScheduleSync = () => {
        onSchedule(
            frequency,
            filterAttribute,
            priceImport,
            withCustomFields,
            withImages,
            withImportPreExistingProductsOnly,
            withCategories
        );
        closeModal();
    };

    const handlePriceImportChange = (value: boolean) => {
        setPriceImport(value);
    };

    const handleCustomFieldsFilterChange = (value: boolean) => {
        setCustomFieldsFilter(value);
    };

    const handleImagesFilterChange = (value: boolean) => {
        setImagesFilter(value);
    };

    const handleImportPreExistingProductsOnlyFilterChange = (
        value: boolean
    ) => {
        setImportPreExistingProductsOnlyFilter(value);
    };

    const handleCategoriesFilterChange = (value: boolean) => {
        setCategoriesFilter(value);
    };

    const intl = useIntl();

    return (
        <Modal
            onClose={closeModal}
            closeTitle={intl.formatMessage({
                defaultMessage: 'Cancel',
                id: '47FYwb',
            })}
            illustration={<ProductsIllustration />}
        >
            <Modal.SectionTitle color='brand'>
                <FormattedMessage
                    defaultMessage='Scheduled synchronization'
                    id='D3e3T3'
                />
            </Modal.SectionTitle>
            <Modal.Title>
                <SynchronizationScopeLabel scope={scope} />
            </Modal.Title>

            <ModalContent>
                <Helper level='info'>
                    <FormattedMessage
                        defaultMessage='Set the frequency of your automatic data update between PIM catalog and BigCommerce store.'
                        id='SP76wK'
                    />
                </Helper>
                <br />
                <Field
                    label={intl.formatMessage({
                        defaultMessage: 'Frequency',
                        id: 'vAW30j',
                    })}
                >
                    <SelectInput
                        value={frequency}
                        onChange={onFrequencyChange}
                        clearable={false}
                        emptyResultLabel=''
                        openLabel=''
                    >
                        <SelectInput.Option value='never'>
                            <FormattedMessage
                                defaultMessage='Never'
                                id='du1laW'
                            />
                        </SelectInput.Option>
                        <SelectInput.Option value='daily'>
                            <FormattedMessage
                                defaultMessage='Daily'
                                id='zxvhnE'
                            />
                        </SelectInput.Option>
                        <SelectInput.Option value='weekly'>
                            <FormattedMessage
                                defaultMessage='Weekly'
                                id='/clOBU'
                            />
                        </SelectInput.Option>
                        <SelectInput.Option value='monthly'>
                            <FormattedMessage
                                defaultMessage='Monthly'
                                id='wYsv4Z'
                            />
                        </SelectInput.Option>
                    </SelectInput>
                </Field>
                <br />
                {frequency !== 'never' && (
                    <>
                        <Field
                            label={intl.formatMessage({
                                id: '+fhF3Y',
                                defaultMessage: 'Filter attribute',
                            })}
                        >
                            <SelectInput
                                value={filterAttribute}
                                onChange={setFilterAttribute}
                                clearable={true}
                                emptyResultLabel=''
                                openLabel=''
                            >
                                {filterPimAttributes.map((option) => (
                                    <SelectInput.Option
                                        key={option.code}
                                        value={option.code}
                                    >
                                        {option.label}
                                    </SelectInput.Option>
                                ))}
                            </SelectInput>
                        </Field>
                        <StyledHelper level='info' inline>
                            <FormattedMessage
                                defaultMessage='Please select a PIM attribute that will be used to filter the products to be synchronized.'
                                id='dodKbD'
                            />
                        </StyledHelper>
                        <br />

                        {!isProductMediaMappingEmpty && (
                            <>
                                <Checkbox
                                    checked={withImages}
                                    onChange={handleImagesFilterChange}
                                >
                                    <CheckboxLabelContainer>
                                        <FormattedMessage
                                            id='xRxTHW'
                                            defaultMessage='Import images'
                                        />
                                    </CheckboxLabelContainer>
                                </Checkbox>
                            </>
                        )}

                        {!isCustomFieldMappingEmpty && (
                            <>
                                <br />
                                <Checkbox
                                    checked={withCustomFields}
                                    onChange={handleCustomFieldsFilterChange}
                                >
                                    <CheckboxLabelContainer>
                                        <FormattedMessage
                                            id='hMMrUQ'
                                            defaultMessage='Import custom fields'
                                        />
                                    </CheckboxLabelContainer>
                                </Checkbox>
                            </>
                        )}
                        <br />
                        <Checkbox
                            checked={priceImport}
                            onChange={handlePriceImportChange}
                        >
                            <CheckboxLabelContainer>
                                <FormattedMessage
                                    id='y2LOM0'
                                    defaultMessage='Import prices'
                                />
                            </CheckboxLabelContainer>
                        </Checkbox>

                        {isPreExistingCatalogMappingConfigured && (
                            <>
                                <br />
                                <Checkbox
                                    checked={withImportPreExistingProductsOnly}
                                    onChange={
                                        handleImportPreExistingProductsOnlyFilterChange
                                    }
                                >
                                    <CheckboxLabelContainer>
                                        <FormattedMessage
                                            defaultMessage='Import pre-existing products only'
                                            id='Gm34kQ'
                                        />
                                    </CheckboxLabelContainer>
                                </Checkbox>
                                <CheckboxHelper level='info' inline>
                                    <FormattedMessage
                                        defaultMessage='Apply this filter to synchronize only PIM products with pre-existing BigCommerce products.'
                                        id='kk8fHt'
                                    />
                                </CheckboxHelper>
                            </>
                        )}

                        <br />
                        <Checkbox
                            checked={withCategories}
                            onChange={handleCategoriesFilterChange}
                        >
                            <CheckboxLabelContainer>
                                <FormattedMessage
                                    defaultMessage='Import categories'
                                    id='ve2Uwu'
                                />
                            </CheckboxLabelContainer>
                        </Checkbox>
                    </>
                )}
                <br />
            </ModalContent>

            <Modal.BottomButtons>
                <Button level='tertiary' onClick={closeModal}>
                    <FormattedMessage defaultMessage='Cancel' id='47FYwb' />
                </Button>
                <Button level='primary' onClick={handleScheduleSync}>
                    <FormattedMessage defaultMessage='Save' id='jvo0vs' />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};

export { ScheduledSyncConfigModal };
