import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { CustomModal, Spacer } from '../../../../component';
import { useCurrentStore } from '../../../MultiStore';
import { SaveActionButtons } from '../../component/MediaMapping/SaveActionButtons';
import { SelectMediaSourceSection } from '../../component/MediaMapping/SelectMediaSourceSection';
import { AssetAttributes } from './component/AssetAttributes';
import { ImageAttributes } from './component/ImageAttributes';
import { useSaveMediaMapping } from './hook/useSaveMediaMapping';
import { MediaSource } from '../../model/MediaSource';
import { useDispatch, useSelector } from './store/StoreProvider';

export const MediaMapping = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { currentStoreId } = useCurrentStore();

    const dispatch = useDispatch();
    const { mediaSource, isDirty, isEmpty } = useSelector((state) => ({
        mediaSource: state.mediaSource,
        isDirty: state.mappingIsDirty,
        isEmpty:
            state.mediaMapping.length === 0 && state.assetMapping.length === 0,
    }));
    const { saveMediaMapping } = useSaveMediaMapping();

    const navigateToPreviousPage = async () => {
        await queryClient.invalidateQueries([
            currentStoreId,
            'getMediaMapping',
        ]);
        navigate('..');
    };

    const handleChange = (value: MediaSource) => {
        dispatch({
            type: 'mediaSource/source/changed',
            mediaSource: value,
        });
    };

    const handleSave = async () => {
        await saveMediaMapping();
        navigateToPreviousPage();
    };

    const handleCancel = () => {
        navigateToPreviousPage();
    };

    const handleImageMappingChange = (attributeCode: string | null) => {};

    const handleAssetMappingChange = (
        value:
            | { attributeCode: null }
            | { attributeCode: string; assetAttributeCode: null | string }
    ) => {};

    return (
        <CustomModal
            title={intl.formatMessage({
                id: 'OBUN49',
                defaultMessage: 'Media Mapping',
            })}
            subTitle={intl.formatMessage({
                id: 'EGMWtj',
                defaultMessage: 'Big commerce target: Image',
            })}
            actions={
                <SaveActionButtons
                    onSave={handleSave}
                    onCancel={handleCancel}
                    disabled={!isDirty}
                />
            }
        >
            <SelectMediaSourceSection
                contextProduct='native-field'
                value={mediaSource}
                onChange={handleChange}
                handleImageMappingChange={handleImageMappingChange}
                handleAssetMappingChange={handleAssetMappingChange}
                disabled={!isEmpty}
            />

            <Spacer></Spacer>

            {mediaSource === MediaSource.Images && <ImageAttributes />}
            {mediaSource === MediaSource.Assets && <AssetAttributes />}
        </CustomModal>
    );
};
